import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "./components/App";
import MainLayout from "./components/layout/MainLayout";
import Contact from "./components/page/contact/Contact";
import ErrorPage from "./components/page/error/ErrorPage";
import SignUp from "./components/page/signUp/SignUp";
import Faq from "./components/page/faq/Faq";
import "./index.css";
import Login from "./components/page/login/Login";
import AuctionDetails from "./components/page/auctionDetails/AuctionDetails";
import Dashboard from "./components/page/dashboard/Dashboard";
import Blog from "./components/page/blog/Blog";
import BlogDetails from "./components/page/BlogDetails/BlogDetails";
import LiveAuction from "./components/page/LiveAuction.jsx/LiveAuction";
import MyAuction from "./components/page/MyAuction/MyAuction";
import FollowedAuction from "./components/page/FollowedAuction/FollowedAuction";

import HowItWork from "./components/page/howItWork/HowItWork";
import About from "./components/page/about/About";

import Merchant from "./components/page/joinMerchant/Merchant";
import { AuthContextProvider } from "./context/authContextProvider";

import { ToastContainer, toast } from "react-toastify";

function Root() {
  return (
    <>
      <ToastContainer></ToastContainer>
      <BrowserRouter basename="/">
        <AuthContextProvider>
          <Switch>
            <Route exact path="/" component={MainLayout} />

            <Layout>
              <Route
                exact
                path={`/about`}
                component={About}
              />

              <Route
                exact
                path={`/contact`}
                component={Contact}
              />
              <Route
                exact
                path={`/error`}
                component={ErrorPage}
              />
              <Route
                exact
                path={`/signup`}
                component={SignUp}
              />
              <Route
                exact
                path={`/login`}
                component={Login}
              />
              <Route
                exact
                path={`/auction-details/:product`}
                component={AuctionDetails}
              />
              <Route
                exact
                path={`/dashboard`}
                component={Dashboard}
              />
              <Route
                exact
                path={`/blog`}
                component={Blog}
              />
              <Route
                exact
                path={`/blog-details`}
                component={BlogDetails}
              />
              <Route
                exact
                path={`/live-auction`}
                component={LiveAuction}
              />

              <Route
                exact
                path={`/my-auction`}
                component={MyAuction}
              />

              <Route
                exact
                path={`/followed`}
                component={FollowedAuction}
              />
              <Route
                exact
                path={`/live-auction/:search`}
                component={LiveAuction}
              />
              <Route
                exact
                path={`/how-works`}
                component={HowItWork}
              />
              <Route
                exact
                path={`/faq`}
                component={Faq}
              />
              <Route
                exact
                path={`/join-merchant`}
                component={Merchant}
              />
            </Layout>
          </Switch>
        </AuthContextProvider>
      </BrowserRouter>
    </>
  );
}

export default Root;

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);
