import React from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import LoginWrap from './LoginWrap'

function Login() {
  return (
    <>
     <LoginWrap/> 
    </>
  )
}

export default Login