import React from "react";
import { Link } from "react-router-dom";
import Counter from "../../common/Counter";

function LiveAuctionCard({ item }) {
  const states = {
    Won: {
      text: "Won",
      color: "green",
    },
    Lose: {
      text: "Lost",
      color: "red",
    },
    Finished: {
      text: null,
    },
    Running: {
      text: null,
    },
    Unauthenticated: {
      text: null,
    },
    Wining: {
      text: "Winning",
      color: "green",
    },
    Loosing: {
      text: "Losing",
      color: "red",
    },
  };

  return (
    <>
      <Link
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        to={`${process.env.PUBLIC_URL}/auction-details/${item?.id}`}
        className="eg-card auction-card1"
      >
        <div className="auction-img">
          <img
            alt="images"
            className=" object-cover aspect-square"
            src={`${process.env.REACT_APP_SERVER_URL}/storage/uploads/${item?.image}`}
          />
          <div className="auction-timer">
            <div className="countdown" id="timer1">
              <h4>
                <Counter date={item?.deadline} />
              </h4>
            </div>
          </div>
        </div>
        <div className="absolute top-0 p-4 right-0">
          {states[item.state]?.text ? <div className={`font-semibold rounded px-3 flex gap-2 justify-center items-center py-1  bg-white !border-opacity-20  !border-${states[item.state].color}-600 border !text-${states[item.state].color}-900`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-ticket"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              className={`text-${states[item.state].color}-600`}
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M15 5l0 2"></path>
              <path d="M15 11l0 2"></path>
              <path d="M15 17l0 2"></path>
              <path d="M5 5h14a2 2 0 0 1 2 2v3a2 2 0 0 0 0 4v3a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-3a2 2 0 0 0 0 -4v-3a2 2 0 0 1 2 -2"></path>
            </svg>
            {states[item.state].text}
          </div> : null}
        </div>
        <div className="auction-content">
          <div className="flex justify-between gap-4">
            <div>
              <h4>
                <div className="text-slate-600 !font-regular text-lg ">
                  {item?.title}
                </div>
              </h4>

              <p className="mt-2">
                Deri 
                {new Date(item?.deadline).toLocaleString("en-UK", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })}
              </p>
            </div>
            <div className="flex flex-col gap-2 items-end">
              <p className="!text-xl !text-slate-900 !font-semibold">
                ${item?.price?.toFixed(2)}
              </p>

              <div className="eg-btn btn--primary btn--sm px-4 py-2">
                Place a Bid
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}

export default LiveAuctionCard;
