import React from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import SignUpWrap from './SignUpWrap'

function SignUp() {
  return (
    <>
     <SignUpWrap/>
    </>
  )
}

export default SignUp