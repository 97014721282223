import React from "react";
import { Link } from "react-router-dom";

function Pagination({ setCurrentPage, currentPage, totalPages }) {
  const showPages = 5; // Number of pages to show (current page + 2 adjacent pages)

  // Calculate the range of page numbers to show, centered around the current page
  const startPage = Math.max(1, currentPage - 1);
  const endPage = Math.min(totalPages, startPage + showPages - 1);

  const pages = [];

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return (
    <div className="row">
      <nav className="pagination-wrap">
        <ul className="pagination d-flex justify-content-center gap-md-3 gap-2">
          {currentPage > 1 && (
            <li
              className="page-item"
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              <p className="page-link">Previous</p>
            </li>
          )}

          {pages.map((page) => (
            <li
              key={page}
              className={`page-item ${currentPage === page ? "active" : ""}`}
            >
              <p className="page-link" onClick={() => setCurrentPage(page)}>
                {page}
              </p>
            </li>
          ))}

          {currentPage < totalPages && (
            <li
              className="page-item"
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              <p className="page-link">Next</p>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}

export default Pagination;
