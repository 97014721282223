import React from "react";
import { Link } from "react-router-dom";

function FaqWrap() {
  return (
    <>
      <div className="faq-section pt-120 pb-120">
        <div className="container">
          <div className="row d-flex justify-content-center gy-5">
            <div className="col-lg-12 col-md-12 text-center order-lg-2 order-1">
              <h2 className="section-title3">Puna e AnkandiOnline</h2>
              <div
                className="faq-wrap wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Si punon, vepron ankandi online?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        AnkandiOnline është një platformë e dedikuar në
                        lehtësimin e blerjes dhe shitjes së makinave të
                        përdorura për kompani dhe individë private. Koncepti i
                        thjeshtë i biznesit tonë mbetet i pandryshuar: shitesit
                        marrin pagesën e drejtë për makinat e tyre në momentin e
                        shitjes, ndërsa blerësit përjetojnë një transaksion të
                        sigurt duke u informuar për gjendjen reale të makinës.
                        Individët që zgjedhin të shesin makinën e tyre përmes
                        nesh përfitojnë nga ndihma e një skuadre të përkushtuar
                        gjatë gjithë procesit të shitjes. Ne vlerësojmë,
                        pastrojmë dhe prezantojmë makinën në platformën tonë tek
                        blerësit potencial, menaxhojmë transferimin e pronësisë
                        dhe sigurojmë që shitesit të marrin menjëherë pagesën e
                        tyre në momentin e shitjes së automjetit.
                        <br></br>
                        <br></br>
                        Për blerësit, kjo përkthehet në një kuptim transparent
                        të gjendjes së makinës përmes dokumentacionit të tij.
                        Blerja e një makinë përmes AnkandiOnline gjithashtu
                        garanton të drejtat e konsumatorit nën Aktin e Blerjeve
                        të Konsumatorit, duke e fuqizuar blerësin të ngritë
                        shqetësime nëse është e nevojshme.
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Si punon, vepron ankandi online?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        AnkandiOnline është një platformë e dedikuar në
                        lehtësimin e blerjes dhe shitjes së makinave të
                        përdorura për kompani dhe individë private. Koncepti i
                        thjeshtë i biznesit tonë mbetet i pandryshuar: shitesit
                        marrin pagesën e drejtë për makinat e tyre në momentin e
                        shitjes, ndërsa blerësit përjetojnë një transaksion të
                        sigurt duke u informuar për gjendjen reale të makinës.
                        Individët që zgjedhin të shesin makinën e tyre përmes
                        nesh përfitojnë nga ndihma e një skuadre të përkushtuar
                        gjatë gjithë procesit të shitjes. Ne vlerësojmë,
                        pastrojmë dhe prezantojmë makinën në platformën tonë tek
                        blerësit potencial, menaxhojmë transferimin e pronësisë
                        dhe sigurojmë që shitesit të marrin menjëherë pagesën e
                        tyre në momentin e shitjes së automjetit.
                        <br></br>
                        <br></br>
                        Për blerësit, kjo përkthehet në një kuptim transparent
                        të gjendjes së makinës përmes dokumentacionit të tij.
                        Blerja e një makinë përmes AnkandiOnline gjithashtu
                        garanton të drejtat e konsumatorit nën Aktin e Blerjeve
                        të Konsumatorit, duke e fuqizuar blerësin të ngritë
                        shqetësime nëse është e nevojshme.
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Si mund të regjistrohem në platform?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Për të pasur mundësi që të merrni pjesë në ankandet e
                        makinave, ju duhet të jeni të regjistruar në platformën
                        tonë. Regjistrohu këtu “linku”.
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Çfarë do të thotë certifikata “AnkandiOnline”
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Të gjitha makinat që vendosen për shitje përmes Kvdbil I
                        nënshtrohen një testimi të detajuar. Ne automjetet e
                        certifikuara nga AnkandiOnline përfshihen ato automjete
                        që sipas rezultateve të testit, plotësojnë kërkesat e
                        veçanta të përcaktuara për gjendjen dhe funksionimin e
                        tyre. Automjetet përmbushin, midis të tjerash, kriteret
                        e mëposhtme; nuk ka probleme në motor, kutinë dhe
                        ndërruesin e shpejtësisë, nuk janë më të vjetra se 10
                        vite dhe nuk kanë më shumë se 300,000 kilometra në
                        kilometrazhën e udhëtuar.
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Si sigurohem unë (përdorues) se nuk po manipulohet çmimi
                        në një automjet?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Në AnkandiOnline kemi një staf që gjatë kohës së
                        zhvillimit të çdo ankandi monitoron ofertat e
                        përdoruesve. Kjo mundëson në një proces të rregullt të
                        zhvillimit të ankandit, duke mbrojtur dhe ndihmuar
                        pjesëmarrësit e ankandeve. Nëse ndodh që të ketë
                        vendosje të ofertave ilegale, të pa përgjegjshme nga
                        ofertuesi, atëherë ndaj tij do të merren masat e
                        nevojshme, duke përfshirë dhe largimin nga platforma.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Cili është orari i punës së AnkandiOnline?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Zyret e AnkandiOnline janë të hapur çdo ditë nga e Hëna
                        në të Premtë nga ora 08:00 deri në orën 16:00. Për çdo
                        informacion mbi dorëzimin e automjetit apo marrjen e
                        tij, mund të na kontaktoni në numrat tanë.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Çfarë duhet të bëj nëse shfaqen probleme pas blerjes?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Në një rast të tillë, ju kontaktoni departamentin tonë
                        të ankesave. Ankesat duhet të paraqiten me shkrim,
                        kështu që është më e lehtë të na dërgoni një email në
                        ankandionline@gmail.com. Në ankesën me shkrim duhet të
                        përfshihen të dhënat si numri i regjistrimit të
                        automjetit, një përshkrim të problemit. Sigurisht, ne
                        jemi gjithashtu të interesuar për çdo informacion dhe
                        rrethanë tjetër të rëndësishme që mund të ketë rëndësi
                        për çështjen.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Si përcaktohet çmimi i një makine para vendosjes në
                        platformë?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Në bazë të përvojës sonë në tregun e makinave ne
                        përcaktojmë çmimin e automjetit tuaj duke përfshirë
                        çmimet e automjeteve në tregun tonë. Baza jonë e të
                        dhënave përfshin një numër të lartë të markave të
                        makinave dhe modeleve të tyre, ku në bazë të tyre
                        arrijmë të përcaktojmë çmimin e automjetit tuaj.
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Çfarë ndodh kur përfundon ankandi?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Kur përfundon ankandi, ne kontaktojmë fituesin e
                        ankandit dhe përcaktojmë kohën e tërheqjes së makines në
                        zyrat tona. Kur personi tërheq makinën, pronësia e
                        makines ndërrohet dhe paratë dorezohen te shitesi i
                        makines.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <h2 className="section-title3 mt-12">Blerja e Makines</h2>
              <div
                className="faq-wrap wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Si mund ta paguaj automjetin?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Pagesa mund të bëhet në disa mënyra, duke përfshirë
                        pagesën me para të gatshme në momentin e tërheqjes së
                        makinës, por edhe me transfer bankar.
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Si është mënyra e tërheqjes së makinës?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Kur blini apo fitoni një makinë dhe zgjidhni që
                        automjeti t'iu dorëzohet në shtëpi, keni të drejtën e
                        anulimit brenda 10 ditësh, me një kosto rikthimi. Nëse
                        merrni makinën tuaj në objektin tonë pas përfundimit të
                        ankandit, nuk ka të drejtë tërheqjeje nga blerja.
                        Megjithatë, pavarësisht nëse blini makinën në
                        AnkandiOnline, gjithmonë keni të drejtën për ankesë nëse
                        diçka rezulton gabim pas blerjes.
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">Vendosja e ofertave</div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Ofertat vendosen në kutinë e ofertave kur ankandi është
                        aktiv. Vlera minimale e një oferte është 50 Euro, dhe ju
                        mund të shihni ofertat e pjesëmarrësve të tjerë duke
                        përfshirë statusin e ofertës suaj.
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Sa kushton transporti i makinës?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Transporti i automjetit varet nga vendndodhja juaj dhe
                        ju mund të merrni informata shtesë duke na kontaktuar në
                        numrat tanë.
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        A është TVSH e përfshirë në automjetet?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Të gjitha çmimet dhe ofertat janë të përfshira në TVSH.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Çka ndodh nëse shitesi nuk pajtohet me çmimin e arritur
                        në ankand?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Nëse ankandi përfundon nën çmimin e dëshiruar nga
                        shitesi, ne kontaktojmë shitesin nëse pajtohet me çmimin
                        e arritur në ankand. Nëse shitesi nuk pajtohet, ankandi
                        nuk realizohet, dhe të gjithë pjesëmarrësit do të
                        lirohen nga të gjitha tarifat. Tarifat përfshihen vetëm
                        në raste kur ankandi realizohet me sukses.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Kur mund ta tërheq automjetin e fituar në ankand?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Nëse keni fituar një ankand në platformën tonë, ju duhet
                        ta tërhiqni brenda 3 ditëve të punës prej momentit kur
                        përfundon ankandi. Për përcaktim të kohës së tërheqjes
                        apo informata të tjera shtesë, na kontaktoni në numrat
                        tanë të telefonit.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">Kur përfundon ankandi?</div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Çdo ankand në AnkandiOnline ka një kohë të fillimit dhe
                        të përfundimit. Ofertat mund të vendosen deri në
                        përfundimin e një ankandi. Nëse një ofertë vendoset
                        brenda 60 sekondave të fundit të një ankandi, atëherë
                        koha e përfundimit të ankandit zgjerohet për 3 minuta."
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Pergjegjesia e AnkandiOnline per nje makine?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Ankandi Online kryen intervista dhe mbledh
                        dokumentacionin për çdo mjet, duke siguruar një
                        prezantim transparent në platformën tonë. Megjithatë,
                        dëshirojmë të sqarojmë se nuk marrim përgjegjësi për
                        ndonjë moskorrespondencë të mundshme lidhur me gjendjen
                        e mjetit ose informacionin e dhënë. Shitësit janë
                        përgjegjës për prezantimin e detajeve të sakta dhe të
                        sinqerta për secilin mjet. Ky informacion kalon nëpër
                        verifikim nga ana e AnkandiOnline për besueshmëri para
                        se Ankandi Online të përfundojë listën. Ju lutemi të
                        keni parasysh se nuk jemi përgjegjës për çështje të
                        lindura nga kushte të pashpallura ose gabime të fshehura
                        nga shitësi.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <h2 className="section-title3 mt-12">Shitja e Makines</h2>
              <div
                className="faq-wrap wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Si mund ta shes një makinë përmes platformës suaj?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Së pari, ju duhet të na kontaktoni përmes numrave tanë
                        të telefonit, ku caktohet një datë për dorëzimin e
                        makinës tuaj te zyrat tona, ku vazhdojmë me procedurat e
                        tjera.
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Si e vlerësoni automjetin tim?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Nëse dëshironi të shisni një automjet përmes platformës
                        tonë, ju dorëzoni automjetin tuaj te zyrat tona, ku
                        bëjmë kontrollimin e makines dhe vlerësimin e makines në
                        bazë të tregut aktual.
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Cilat makina i pranoni ju si kompani?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Ne pranojmë makinat të cilat nuk janë më të vjetra se 10
                        vite dhe nuk kanë më shumë se 300,000 kilometra të
                        kaluara. Poashtu, pronari i makines duhet të jetë i
                        njëjti gjatë 3 muajve të fundit.
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Sa zgjat procesi I pagesës për mua si shites?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Pagesa e makines kryhet brenda 3 ditëve të punës pas
                        perfundimit të ankandit. Në raste se ka transfere përmes
                        bankes, procesi mund të zgjatë deri në 7 ditë të punës.
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Si behet pagesa e makines për mua si shites?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Pagesa bëhet në bazë të marrëveshjes mes nesh dhe juve
                        si shites. Pagesat mund të bëhen përmes transferit
                        bankar apo mënyrave tjera.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        A eshte TVSH e perfshir ne automjetet?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Te gjitha qmimet dhe ofertat jane te perfshira ne TVSH.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Sa eshte tarifa per shitjen e nje makine permes
                        ankandiOnline?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Kur nje makine shitet permes ankandiOnline ka disa pika
                        qe e favorizojne shitjen permes platformes sone. Ne
                        shitjen e nje makine permes ankandiOnline perfshihet
                        marketing permes platformes dhe rrjeteve tjera sociale.
                        Ne tarifen e shitjes se makines perfshihet testimi,
                        pastrimi dhe marketing.
                        <br></br>
                        <br></br>
                        Nëse makinën e shisni për EUR 0 – EUR 9999, komisioni
                        është EUR 1000. <br></br>
                        Nëse makinën e shisni për EUR 10000 - EUR 19999,
                        komisioni është EUR 1000.<br></br>
                        Nëse makinën e shisni për EUR 20000 - EUR 29999,
                        komisioni është EUR 1250.<br></br>
                        Nëse makinën e shisni për EUR 30000 - EUR 39999,
                        komisioni është EUR 1550.<br></br>
                        Nëse makinën e shisni për EUR 40000 - EUR 49999,
                        komisioni është EUR 1900.<br></br>
                        Nëse makinën e shisni për EUR 50000 - EUR 59999,
                        komisioni është EUR 2250.<br></br>
                        Nëse makinën e shisni për EUR 60000 - EUR 79999,
                        komisioni është EUR 2800.<br></br>
                        Nëse makinën e shisni për EUR 80000 - EUR 99999,
                        komisioni është EUR 3350.<br></br>
                        Nëse makinën e shisni për EUR 100000 ose më shumë,
                        ofrohet një ofertë.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Sa zgjate nje ankand ne ankandiOnline?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Nje makine se pari vendoset ne platformë për 3-4 ditë
                        para se të fillojë ankandi për atë makine dhe pastaj
                        pasi të fillojë ankandi ai zgjat për 7 ditë.
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        A mund ta ndryshoj çmimin e rezervuar të makines sime
                        pas vendosjes në ankand?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Çmimi i rezervuar apo çmimi minimal i kërkuar nga
                        shitesi që përcaktohet mes nesh dhe shitesit mund të
                        ndryshohet nga shitesi deri në 8 ore para perfundimit të
                        ankandit dhe nëse çmimi i rezervuar nuk është arritur
                        akoma. Ne rast se koha e mbetur është më pak se 8 ore
                        apo çmimi ka kaluar çmimin e rezervuar atëherë nuk mund
                        të ketë ndryshim të çmimit.
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Çka ndodh kur nuk është arritur çmimi i rezervuar?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Nese ankandi perfundon nën çmimin e dëshiruar nga
                        shitesi, ne kontaktojm shitesin për te pare nese
                        pajtohet me çmimin e arritur ne ankand. Nese shitesi nuk
                        pajtohet, ankandi nuk realizohet, dhe te gjithe
                        pjesmarresit do te lirohen nga te gjitha tarifat.
                        Tarifat perfshihen vetem ne raste kur ankandi realizohet
                        me sukses.
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Kur mund ta terheq automjetin e fituar ne ankand?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Nese keni fituar nje ankand ne platformen tone, ju duhet
                        ta terheqni brenda 3 diteve te punes prej momentit kur
                        perfundon ankandi. Per percaktim te kohes se terheqjes
                        apo informata te tjera shtes , na kontaktoni ne numrat
                        tanë te telefonit.
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Si mund të di nëse oferta ime është vendosur?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Kur jeni kyçur dhe keni vendosur një ofertë, mund të
                        shihni një shenjë të gjelbër me shkrimin "Oferta juaj".
                        Historia e plotë e ofertave shfaqet hapur në reklamën e
                        makinës, ju gjithashtu mund të shihni historinë tuaj të
                        ofertave.
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        A mund të eksportoj makinën që blej?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Nëse makina mund të eksportohet apo jo, shfaqet në
                        përshkrimin e makinës, nën rubrikën "Të dhënat e
                        makinës."
                      </p>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div className="faq-button">
                        Çfarë do të thotë çmimi i rezervuar?
                      </div>
                    </h2>

                    <div className="accordion-body">
                      <p>
                        Çmimi i rezervuar është çmimi më i ulët që shitësi
                        kërkon për makinën. Çfarë është çmimi i rezervuar mbahet
                        fshehur nga ofertuesit, por është gjithmonë e mundur të
                        shihet nëse çmimi i rezervuar është arritur ose jo.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FaqWrap;
