
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { useAuthContext } from "../../../context/authContext";

function SignUpWrap() {
  const [openEye, setOpenEye] = useState(true);

  const { register, goBack } = useAuthContext();

  useEffect(() => {
    goBack();
  }, []);

  const [error, setError] = useState(null);

  const handleEyeIcon = () => {
    setOpenEye(!openEye);
  };

  const handleLogin = async (values) => {
    setError(null);
    try {
      register(values, setError);
    } catch {
      setError("Invalid email or password!");
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    personal_number: Yup.number().required("Personal number is required"),
    name: Yup.string().required("Name is required"),
  });

  return (
    <>
     <div className="signup-section pt-120 pb-120">
        <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/section-bg.png'} className="section-bg-top" />
        <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/section-bg.png'} className="section-bg-bottom" />
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div className="form-wrapper wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                <div className="form-title">
                  <h3>Sign Up</h3>
                  <p>Do you already have an account? <Link to={`${process.env.PUBLIC_URL}/login`} onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>Log in here</Link></p>
                </div>

                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleLogin}
                >
                  <Form className="w-100">
                    <div className="row">
                    <div className="col-12">
                        <div className="form-inner">
                          <label>Emri *</label>
                          <Field
                            type="text"
                            name="name"
                            placeholder="Enter Your Name"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="error  text-red-500 text-sm"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-inner">
                          <label>Numri personal *</label>
                          <Field
                            type="number"
                            name="personal_number"
                            placeholder="Enter Your NR"
                          />
                          <ErrorMessage
                            name="personal_number"
                            component="div"
                            className="error  text-red-500 text-sm"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-inner">
                          <label>Enter Your Email *</label>
                          <Field
                            type="email"
                            name="email"
                            placeholder="Enter Your Email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error  text-red-500 text-sm"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-inner">
                          <label>Password *</label>
                          <Field
                            type={openEye ? "password" : "text"}
                            name="password"
                            placeholder="Password"
                          />
                          <i
                            className={
                              openEye
                                ? "bi bi-eye-slash"
                                : "bi bi-eye-slash bi-eye"
                            }
                            id="togglePassword"
                            onClick={handleEyeIcon}
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="error text-red-500 text-sm"
                          />
                          {error && <p className="text-red-500 text-sm">{error}</p>}
                        
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="account-btn">
                    Create Account
                    </button>
                  </Form>
                </Formik>
                
              
              </div>
            </div>
          </div>
        </div>
      </div>   
    </>
  )
}

export default SignUpWrap