import React, { useEffect, useRef, useState } from "react";

function Counter({ date }) {
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");
  const [timerDay, setTimerDay] = useState("00");
  let interval = useRef();

  const startTimer = () => {
    const countdownDate = new Date(date);

    interval = setInterval(() => {
      var now = new Date().getTime();
    
      const distance = countdownDate - now  - 3600000;;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setTimerDay(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  };
  useEffect(() => {
    startTimer();

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clearInterval(interval.current);
    };
  });
  return (
    <>
      {timerDay > 0 && (
        <>
          <span id="hours1">{timerDay}</span>D :&nbsp;
        </>
      )}
      <span id="hours1">{timerHours}</span>H :&nbsp;
      <span id="minutes1">{timerMinutes}</span>M :&nbsp;
      <span id="seconds1">{timerSeconds}</span>S
    </>
  );
}

export default Counter;
