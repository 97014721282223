import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { useAuthContext } from "../../../context/authContext";

function LoginWrap() {
  const [openEye, setOpenEye] = useState(true);

  const { login, goBack } = useAuthContext();

  useEffect(() => {
    goBack();
  }, []);

  const [error, setError] = useState(null);

  const handleEyeIcon = () => {
    setOpenEye(!openEye);
  };

  const handleLogin = async (values) => {
    setError(null);
    try {
      login(values, setError);
    } catch {
      setError("Invalid email or password!");
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  return (
    <>
      <div className="login-section pt-120 pb-120">
      
        <div className="container md:pt-0 pt-20">
          <div className="row d-flex justify-content-center g-4">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div
                className="form-wrapper wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <div className="form-title">
                  <h3>Log In</h3>
                  <p>
                    New Member?{" "}
                    <Link
                      to={`${process.env.PUBLIC_URL}/signup`}
                      onClick={() =>
                        window.scrollTo({ top: 0, behavior: "smooth" })
                      }
                    >
                      signup here
                    </Link>
                  </p>
                </div>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleLogin}
                >
                  <Form className="w-100">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-inner">
                          <label>Enter Your Email *</label>
                          <Field
                            type="email"
                            name="email"
                            placeholder="Enter Your Email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error  text-red-500 text-sm"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-inner">
                          <label>Password *</label>
                          <Field
                            type={openEye ? "password" : "text"}
                            name="password"
                            placeholder="Password"
                          />
                          <i
                            className={
                              openEye
                                ? "bi bi-eye-slash"
                                : "bi bi-eye-slash bi-eye"
                            }
                            id="togglePassword"
                            onClick={handleEyeIcon}
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="error text-red-500 text-sm"
                          />
                          {error && <p className="text-red-500 text-sm">{error}</p>}
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="account-btn">
                      Log in
                    </button>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginWrap;
