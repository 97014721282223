import React, { useEffect, useState } from "react";
import { AuthContext } from "./authContext";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";

import Cookies from "js-cookie";

export const AuthContextProvider = ({ children }) => {
  const history = useHistory();

  const [user, setUser] = useState();

  const token = user?.access_token;

  useEffect(() => {
    if (!user) {
      const u = Cookies.get("user");
      if (u) {
        setUser(JSON.parse(u));
      }
    }
  }, []);

  const isLogin = user?.access_token != null;

  const logout = () => {
    setUser(null);
    Cookies.remove("user");

    history.push("/");
  };

  const goBack = () => {
    if (isLogin) {
      if (history.length > 1) {
        history.goBack(); // Go back to the previous page if it exists
      } else {
        history.push("/");
      }
    }
  };

  const login = async (values, setError) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/login`,
        {
          username: values?.email,
          password: values?.password,
          grant_type: "password",
          scope: "",
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT,
        },
        {
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const u = response?.data;
      setUser(u);
      Cookies.set("user", JSON.stringify(u), { expires: 60 });
    } catch {
      return setError("Invalid email or password!");
    }

    if (history.length > 1) {
      history.goBack(); // Go back to the previous page if it exists
    } else {
      history.push("/");
    }
  };

  const register = async (values, setError) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/user/store`,
        values,
        {
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const u = response?.data;
    } catch {
      return setError("Try again!");
    }

    history.push("/login");
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        isLogin,
        user: user?.user,
        goBack,
        logout,
        token,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
