import React, { useEffect, useState } from "react";
import AboutUsCounter from "../../common/AboutUsCounter";
import Breadcrumb from "../../common/Breadcrumb";
import AuctionDetailsWrap from "./AuctionDetailsWrap";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAuthContext } from "../../../context/authContext";
import Pusher from "pusher-js";
import { setPusherClient } from "react-pusher";

function AuctionDetails() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);



  const { token } = useAuthContext();

  const { product } = useParams();

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/products/${product}`,
        {
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data;
      setData(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData().then(() => {
      setLoading(false)
    });
  }, [token]);

  useEffect(() => {
    if(data){
    var pusherClient = new Pusher("5944a901cf08b15746e7", {
      cluster: "eu",
    });

    setPusherClient(pusherClient);

    var channel = pusherClient.subscribe("" + data?.id);
    channel.bind("place-bid", function (data) {
      fetchData();
    });
  }
  }, [data]);

  return (
    <>
      <AuctionDetailsWrap data={data} fetchData={fetchData} loading={loading} />
      <AboutUsCounter data={data} />
    </>
  );
}

export default AuctionDetails;
