import React, { useEffect, useState } from "react";
import Counter from "../../common/Counter";

import Slider from "react-slick";
import axios from "axios";
import { useAuthContext } from "../../../context/authContext";
import { Link, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function AuctionDetailsInfo({ data, fetchData }) {
  const [input, setInput] = useState();
  const [error, setError] = useState();

  const history = useHistory();

  const { token, logout, isLogin } = useAuthContext();

  const states = {
    Won: {
      text: "Won",
      color: "green",
    },
    Lose: {
      text: "Lost",
      color: "red",
    },
    Finished: {
      text: null,
    },
    Running: {
      text: null,
    },
    Unauthenticated: {
      text: null,
    },
    Wining: {
      text: "Winning",
      color: "green",
    },
    Loosing: {
      text: "Losing",
      color: "red",
    },
  };

  const makeBid = async (e) => {
    e.preventDefault();
    if (input != null && parseFloat(input) > data?.price) {
      setError();

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/bid`,
          {
            product_id: data?.id,
            price: parseFloat(input),
          },
          {
            mode: "no-cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (e) {
        if (e.response.status == 401) {
          logout();
        }
        setError(e.response.data?.fail);
      }

      // alert(JSON.stringify(response));
      // if(response.status == 500){
      // }
    } else {
      setError("Vlera duhet te jete mbi minumumin");
    }
  };

  const buyNow = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/buy`,
        {
          product_id: data?.id,
        },
        {
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      fetchData();
      toast("Product bought!", { type: "success" });
      history.push("/my-auction");
    } catch (e) {
      if (e.response.status == 401) {
        logout();
      }
      toast(e.response.data?.fail || "There was an error!", { type: "error" });
    }
  };

  const currentDate = new Date();
  const deadlineDate = new Date(data?.deadline);

  const settings = {
    dots: true,
    customPaging: function (i) {
      return (
        <a className="h-full w-full cursor-pointer">
          <img
            className="h-full w-full"
            src={`${process.env.REACT_APP_SERVER_URL}/uploads/${
              JSON.parse(data?.gallery)[i]
            }`}
          />
        </a>
      );
    },
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [follow, setFollow] = useState(data?.following);

  useEffect(() => {
    if (data) {
      setFollow(data?.following);
    }
  }, [data]);

  const followBid = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/${data?.id}/follow`,
        {},
        {
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response) {
        setFollow(!follow);
      }
    } catch (e) {
      if (e.response.status == 401) {
        logout();
      }
      setError(e.response.data?.fail);
    }
  };

  return (
    <>
      <div className="row g-4 mb-50">
        <div className="col-lg-6 pr-6">
          <Slider {...settings}>
            {data?.gallery &&
              JSON.parse(data?.gallery).map((image) => (
                <img
                  alt="images"
                  className="rounded-lg aspect-[1.3] object-contain"
                  src={`${process.env.REACT_APP_SERVER_URL}/uploads/${image}`}
                />
              ))}
          </Slider>
        </div>
        <div className="col-xl-6 col-lg-5">
          <div className="product-details-right  wow fadeInDown">
            <div className="flex justify-between">
              <h3>{data?.title}</h3>
              <div className="flex gap-2">
                {isLogin && (
                  <div
                    className={`cursor-pointer font-semibold rounded px-3 flex gap-2 justify-center items-center py-1 ${
                      !follow ? "bg-[#11857e] text-white" : "bg-gray-100"
                    }`}
                    onClick={followBid}
                  >
                    {!follow ? "Follow" : "Following"}
                  </div>
                )}
                {states[data?.state]?.text ? (
                  <div
                    className={`font-semibold rounded px-3 flex gap-2 justify-center items-center py-1  bg-white !border-opacity-20  !border-${
                      states[data?.state].color
                    }-600 border !text-${states[data?.state].color}-900`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-ticket"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      className={`text-${states[data?.state].color}-600`}
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M15 5l0 2"></path>
                      <path d="M15 11l0 2"></path>
                      <path d="M15 17l0 2"></path>
                      <path d="M5 5h14a2 2 0 0 1 2 2v3a2 2 0 0 0 0 4v3a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-3a2 2 0 0 0 0 -4v-3a2 2 0 0 1 2 -2"></path>
                    </svg>
                    {states[data?.state].text}
                  </div>
                ) : null}
              </div>
            </div>

            {!(currentDate >= deadlineDate) ? (
              data?.type_id === "2" ? (
                <div className="bid-form">
                  <div className="form-title flex justify-between">
                    <p>
                      Minimum Bid{" "}
                      <b className="font-bold text-green-700">
                        ${data?.price?.toFixed(2)}
                      </b>
                    </p>
                    {data?.deadline && (
                      <div className="text-lg font-semibold">
                        <Counter date={data?.deadline}></Counter>
                      </div>
                    )}
                  </div>
                  <form onSubmit={makeBid}>
                    <div className="form-inner gap-2">
                      <input
                        onChange={(e) => setInput(e.target.value)}
                        value={input}
                        required
                        type="number"
                        placeholder={`$${data?.price?.toFixed(2)}+`}
                      />

                      <button
                        className="eg-btn btn--primary btn--sm"
                        style={{ backgroundColor: "#11857E" }}
                        onSubmit={(e) => e.preventDefault()}
                        type="submit"
                      >
                        Place Bid
                      </button>
                    </div>
                    {error && (
                      <p className="text-sm text-red-900 mt-3 ml-1">{error}</p>
                    )}
                  </form>
                </div>
              ) : (
                <div>
                  {data?.deadline && (
                    <div className="text-lg font-semibold">
                      <Counter date={data?.deadline}></Counter>
                    </div>
                  )}
                  <div className="bid-form pb-0">
                    <div className="flex justify-between">
                      <p className="text-2xl">
                        Price:{" "}
                        <b className="font-bold  text-green-700">
                          ${data?.min_price?.toFixed(2)}
                        </b>
                      </p>

                      <button
                        className="eg-btn p-[10px] px-4 btn--primary btn--sm"
                        style={{ backgroundColor: "#11857E" }}
                        onClick={buyNow}
                      >
                        Buy Now
                      </button>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="bid-form flex justify-center">
                <p className="font-semibold text-lg">Deadline is over!</p>
              </div>
            )}

            <div id="pills-bid" role="tabpanel" aria-labelledby="pills-bid-tab">
              <div className="bid-list-area">
                <ul className="bid-list pb-6">
                  {data?.bids?.map((bid) => (
                    <li>
                      <div className="row d-flex align-items-center">
                        <div className="col-7">
                          <div className="bidder-area">
                            <div className="bidder-img flex bg-slate-100 p-2 rounded-full">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-user-circle"
                                width="36"
                                height="36"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="#11857E"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                                <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855"></path>
                              </svg>
                            </div>
                            <div className="bidder-content">
                              <Link to={"#"}>
                                <h6>{bid?.user?.name}</h6>
                              </Link>
                              <p>{bid?.price?.toFixed(2)}$</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-5 text-end">
                          <div className="bid-time">
                            <p>
                              {new Date(bid?.updated_at).toLocaleString(
                                "en-UK",
                                {
                                  day: "numeric",
                                  month: "numeric",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                }
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuctionDetailsInfo;
